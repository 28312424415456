import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Component } from '../../generated/types';

// TODO: move topics under this slice
interface EnvComponentsState {
  dataComponents: Component[] | undefined; //queries
  stackComponents: Component[] | undefined; // stacks
}

const initialState: EnvComponentsState = {
  dataComponents: undefined,
  stackComponents: undefined,
};

const envComponentsSlice = createSlice({
  name: 'envComponents',
  initialState: {
    value: initialState,
  },
  reducers: {
    setDataComponents: (state, action: PayloadAction<Component[]>) => {
      state.value.dataComponents = action.payload;
    },
    addDataComponent: (state, action: PayloadAction<Component>) => {
      if (!state.value.dataComponents) {
        state.value.dataComponents = [];
      }
      state.value.dataComponents.push(action.payload);
    },
    updateDataComponent: (state, action: PayloadAction<Component>) => {
      if (!state.value.dataComponents) {
        return;
      }
      state.value.dataComponents = state.value.dataComponents?.map(
        (dataComponent) =>
          dataComponent.id === action.payload.id
            ? action.payload
            : dataComponent,
      );
    },
    removeDataComponent: (state, action: PayloadAction<string>) => {
      if (!state.value.dataComponents) {
        return;
      }
      state.value.dataComponents = state.value.dataComponents?.filter(
        (dataComponent) => dataComponent.id !== action.payload,
      );
    },
    setStackComponents: (state, action: PayloadAction<Component[]>) => {
      state.value.stackComponents = action.payload;
    },
    addStackComponent: (state, action: PayloadAction<Component>) => {
      if (!state.value.stackComponents) {
        state.value.stackComponents = [];
      }
      state.value.stackComponents.push(action.payload);
    },
    updateStackComponent: (state, action: PayloadAction<Component>) => {
      if (!state.value.stackComponents) {
        return;
      }
      state.value.stackComponents = state.value.stackComponents?.map(
        (stackComponent) =>
          stackComponent.id === action.payload.id
            ? action.payload
            : stackComponent,
      );
    },
    removeStackComponent: (state, action: PayloadAction<string>) => {
      if (!state.value.stackComponents) {
        return;
      }
      state.value.stackComponents = state.value.stackComponents?.filter(
        (stackComponent) => stackComponent.id !== action.payload,
      );
    },
  },
});

export const {
  // queries
  setDataComponents,
  addDataComponent,
  updateDataComponent,
  removeDataComponent,
  // stacks
  setStackComponents,
  addStackComponent,
  updateStackComponent,
  removeStackComponent,
} = envComponentsSlice.actions;
export default envComponentsSlice.reducer;
