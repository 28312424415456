import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Component } from '../../generated/types';

interface TopicsState {
  topics: Component[] | undefined;
}

const initialState: TopicsState = {
  topics: undefined,
};

const topicsSlice = createSlice({
  name: 'topics',
  initialState: {
    value: initialState,
  },
  reducers: {
    setTopics: (state, action: PayloadAction<Component[]>) => {
      state.value.topics = action.payload;
    },
    addTopic: (state, action: PayloadAction<Component>) => {
      if (!state.value.topics) {
        state.value.topics = [];
      }
      state.value.topics.push(action.payload);
    },
    updateTopic: (state, action: PayloadAction<Component>) => {
      if (!state.value.topics) {
        return;
      }
      state.value.topics = state.value.topics?.map((topic) =>
        topic.id === action.payload.id ? action.payload : topic,
      );
    },
    removeTopic: (state, action: PayloadAction<string>) => {
      if (!state.value.topics) {
        return;
      }
      state.value.topics = state.value.topics?.filter(
        (topic) => topic.id !== action.payload,
      );
    },
  },
});

export const { setTopics, addTopic, updateTopic, removeTopic } =
  topicsSlice.actions;
export default topicsSlice.reducer;
