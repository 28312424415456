import { Box, BoxProps } from '@mui/material';
// Drawers to register
import EditColumnForm, {
  EditColumnFormProps,
} from '../App/Table/EditColumnForm';
import EditTableForm, { EditTableFormProps } from '../App/Table/EditTableForm';
import EditDetailViewForm, {
  EditDetailViewFormProps,
} from '../App/Table/EditDetailViewForm';
import ScheduleVisualizationForm, {
  ScheduleVizFormProps,
} from '../Visualization/ScheduleVisualizationForm';
import AlertSteppedForm, {
  AlertSteppedFormDrawerProps,
} from '../AlertsWorkflow/AlertSteppedForm';
import { ScheduleVizFormValues } from '../Visualization/types';
import LoginForm, {
  LoginFormProps,
  LoginFormValues,
} from '../shared/Drawer/LoginForm';
import { BaseRegisteredDrawerComponentProps } from './types';
import AnalystHintsForm, {
  AnalystHintsFormProps,
} from '../Tune/Persona/AnalystHintsForm';

interface DefaultContentProps
  extends BaseRegisteredDrawerComponentProps,
    BoxProps {}

export type DrawerComponentTypeMap = {
  [DRAWER_IDS.TABLE_APP_EDIT_COLUMN]: {
    contentPropType: EditColumnFormProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.TABLE_APP_EDIT_TABLE]: {
    contentPropType: EditTableFormProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.TABLE_APP_EDIT_DETAIL_VIEW]: {
    contentPropType: EditDetailViewFormProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.SCHEDULE_VISUALIZATION]: {
    contentPropType: ScheduleVizFormProps;
    formValueType: ScheduleVizFormValues;
  };
  [DRAWER_IDS.CREATE_ALERT]: {
    contentPropType: AlertSteppedFormDrawerProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.ANALYST_HINTS]: {
    contentPropType: AnalystHintsFormProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.LOGIN_FORM]: {
    contentPropType: LoginFormProps;
    formValueType: LoginFormValues;
  };
  [DRAWER_IDS.DEFAULT]: {
    contentPropType: DefaultContentProps;
    formValueType: undefined;
  };
  [DRAWER_IDS.NO_DRAWER]: {
    contentPropType: { hideDrawer: () => void };
    formValueType: undefined;
  };
};

// default drawer is an empty drawer which can take children as props
export enum DRAWER_IDS {
  TABLE_APP_EDIT_COLUMN = 'TABLE_APP_EDIT_COLUMN',
  TABLE_APP_EDIT_TABLE = 'TABLE_APP_EDIT_TABLE',
  TABLE_APP_EDIT_DETAIL_VIEW = 'TABLE_APP_EDIT_DETAIL_VIEW',
  SCHEDULE_VISUALIZATION = 'SCHEDULE_VISUALIZATION',
  CREATE_ALERT = 'CREATE_ALERT',
  ANALYST_HINTS = 'ANALYST_HINTS',
  LOGIN_FORM = 'LOGIN_FORM',
  DEFAULT = 'DEFAULT',
  NO_DRAWER = 'NO_DRAWER',
}

export type Registry = {
  [ID in DRAWER_IDS]: {
    component: React.ComponentType<
      DrawerComponentTypeMap[ID]['contentPropType']
    >;
    props?: DrawerComponentTypeMap[ID]['contentPropType'];
  };
};

// Drawers must be registered here
// default props are undefined because this registry don't have details about the props
export const DRAWER_REGISTRY: Registry = {
  [DRAWER_IDS.TABLE_APP_EDIT_COLUMN]: {
    component: EditColumnForm,
    props: undefined,
  },
  [DRAWER_IDS.TABLE_APP_EDIT_TABLE]: {
    component: EditTableForm,
    props: undefined,
  },
  [DRAWER_IDS.TABLE_APP_EDIT_DETAIL_VIEW]: {
    component: EditDetailViewForm,
    props: undefined,
  },
  [DRAWER_IDS.SCHEDULE_VISUALIZATION]: {
    component: ScheduleVisualizationForm,
    props: undefined,
  },
  [DRAWER_IDS.CREATE_ALERT]: {
    component: AlertSteppedForm,
    props: undefined,
  },
  [DRAWER_IDS.ANALYST_HINTS]: {
    component: AnalystHintsForm,
    props: undefined,
  },
  [DRAWER_IDS.LOGIN_FORM]: {
    component: LoginForm,
    props: undefined,
  },
  [DRAWER_IDS.DEFAULT]: {
    component: (props: DefaultContentProps) => <Box {...props} />,
    props: undefined,
  },
  [DRAWER_IDS.NO_DRAWER]: {
    component: () => null,
    props: undefined,
  },
};
