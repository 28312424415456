import { omit } from 'min-dash';
import { ApolloError, useApolloClient } from '@apollo/client';
import {
  deleteTokens,
  setTokens,
  Tokens,
  deleteCurrentUserDetails,
  setCurrentUserDetails,
  deleteSystemUserSelectedEnvironmentId,
} from '../lib/localStorage';
import {
  AcceptPasswordResetInput,
  AcceptUserInviteInput,
  SignInInput,
  SignInResult,
  StartPasswordResetFlowInput,
  useAcceptInviteMutation,
  useAcceptPasswordResetMutation,
  useEmbedAuthScopeLazyQuery,
  useSignInMutation,
  useSignOutMutation,
  useStartPasswordResetMutation,
  useViewerLazyQuery,
  WorkspaceMemberRole,
} from '../generated/types';
import { ResetStoreAction } from '../store';
import { useAppDispatch, useAppSelector } from './store';
import * as Sentry from '@sentry/browser';
import {
  SessionUserState,
  clearSessionUser,
  setSessionUser,
} from '../store/slices/session';
import {
  EmbedAuthScope,
  isValid,
  validators,
  Routes,
} from '@madeinventive/core-types';
import { NextRouter } from 'next/router';
import { INVENTIVE_LOGO } from '../utils/filePaths';

interface CustomNetworkError {
  result: {
    message: string;
    code: string;
    details: {
      message: string;
      stack: string;
    };
  };
}

export const useSession = () => {
  const apolloClient = useApolloClient();
  const storeDispatch = useAppDispatch();

  const [signInMutation] = useSignInMutation();
  const [signOutMutation] = useSignOutMutation();
  const [startResetPasswordMutation] = useStartPasswordResetMutation();
  const [acceptPasswordResetMutation] = useAcceptPasswordResetMutation();
  const [acceptInviteMutation] = useAcceptInviteMutation();
  const [fetchUserDetails] = useViewerLazyQuery();
  const [fetchEmbedAuthScope] = useEmbedAuthScopeLazyQuery();

  const signInAndRetry = async (input: SignInInput) => {
    try {
      return await signIn(input);
    } catch (error: unknown) {
      // retry once if the current access token is invalid
      if (error instanceof ApolloError && error.networkError) {
        const customError = error.networkError as unknown as CustomNetworkError;
        if (customError?.result?.code === 'INVALID_ACCESS_TOKEN') {
          console.error(
            'Invalid access token, clearing tokens and trying again.',
          );
          deleteTokens();
          deleteCurrentUserDetails();
          return await signIn(input);
        }
      } else {
        // otherwise just throw the error is a retry doesn't make sense
        throw error;
      }
    }
  };

  const setUserData = async (signInData: SignInResult) => {
    if (!signInData.accessToken || !signInData.refreshToken) {
      // no refresh token, nothing to do
      return;
    }

    const tokens = omit(signInData, ['__typename']) as Tokens;
    setTokens(tokens);

    const { data: userData } = await fetchUserDetails();
    const userDetails = userData?.viewer.user;
    if (!userDetails) return;

    setCurrentUserDetails(userDetails);
    Sentry.setUser({ id: userDetails?.id, email: userDetails?.email });
    storeDispatch(
      setSessionUser({
        id: userDetails?.id || '',
        isSignedIn: true,
        firstName: userDetails?.firstname || '',
        lastName: userDetails?.lastname || '',
        email: userDetails?.email || '',
        userType: userDetails?.__typename || '',
        systemSelectedEnvironmentId: '',
        customBranding: {
          themeColor:
            (userDetails?.__typename === 'EnvironmentMember' &&
              userDetails.environment.themeColor) ||
            (userDetails?.__typename === 'WorkspaceMember' &&
              userDetails.workspace.environment.themeColor) ||
            '',
          logoUrl:
            (userDetails?.__typename === 'EnvironmentMember' &&
              userDetails.environment.logoUrl) ||
            (userDetails?.__typename === 'WorkspaceMember' &&
              userDetails.workspace.environment.logoUrl) ||
            '',
          iconUrl:
            (userDetails?.__typename === 'EnvironmentMember' &&
              userDetails.environment.iconUrl) ||
            (userDetails?.__typename === 'WorkspaceMember' &&
              userDetails.workspace.environment.iconUrl) ||
            '',
        },

        environmentRole:
          (userDetails?.__typename === 'EnvironmentMember' &&
            userDetails.environmentRole) ||
          '',
        environmentName:
          (userDetails?.__typename === 'EnvironmentMember' &&
            userDetails.environment.name) ||
          (userDetails?.__typename === 'WorkspaceMember' &&
            userDetails.workspace.environment.name) ||
          '',
        environmentId:
          (userDetails?.__typename === 'EnvironmentMember' &&
            userDetails.environment.id) ||
          (userDetails?.__typename === 'WorkspaceMember' &&
            userDetails.workspace.environment.id) ||
          '',
        workspaceRole:
          (userDetails?.__typename === 'WorkspaceMember' &&
            userDetails.workspaceRole) ||
          '',
        workspaceId:
          (userDetails?.__typename === 'WorkspaceMember' &&
            userDetails.workspace.id) ||
          '',
        workspaceName:
          (userDetails?.__typename === 'WorkspaceMember' &&
            userDetails.workspace.name) ||
          '',
      }),
    );
  };

  const signInWithEmbedTokens = async (
    tokens: SignInResult,
    scopeToken?: string,
  ): Promise<EmbedAuthScope | undefined> => {
    try {
      await setUserData(tokens);

      // temporary workaround for pre-existing C1 embeds (e.g Peek)
      // that predate the introduction of embed auth scope
      // @TODO: remove this and make scopeToken required once all
      //        pre-existing embeds have been updated to use the new auth scope
      if (!scopeToken) return { chat: true };

      const { data } = await fetchEmbedAuthScope({
        variables: { embedScopeToken: scopeToken },
      });
      if (
        !data ||
        !isValid<EmbedAuthScope>(validators.EmbedAuthScope, data.embedAuthScope)
      ) {
        // clean up the session if we don't have a valid embed auth scope
        await cleanupSession();
        return;
      }
      return data.embedAuthScope;
    } catch {
      // also clean up if we run into any problem during the sign in process
      await cleanupSession();
    }
  };

  const signIn = async (input: SignInInput) => {
    try {
      const result = await signInMutation({ variables: { input } });
      if (result.data?.signIn) {
        setUserData(result.data.signIn as SignInResult);
      }
    } catch {
      // Nothing to do here just make sure any ApolloError is handled.
      // Showing the error to user is already handled by the error link.
    }
  };

  const resetPassword = async (input: AcceptPasswordResetInput) => {
    try {
      const result = await acceptPasswordResetMutation({
        variables: { input },
      });
      if (result.data?.acceptPasswordReset) {
        setUserData(result.data.acceptPasswordReset as SignInResult);
      }
    } catch {
      // Nothing to do here just make sure any ApolloError is handled.
      // Showing the error to user is already handled by the error link.
    }
  };

  const acceptInvite = async (input: AcceptUserInviteInput) => {
    try {
      const result = await acceptInviteMutation({
        variables: { input },
      });
      if (result.data?.acceptInvite) {
        setUserData(result.data.acceptInvite as SignInResult);
      }
    } catch {
      // Nothing to do here just make sure any ApolloError is handled.
      // Showing the error to user is already handled by the error link.
    }
  };

  const startResetPassword = async (input: StartPasswordResetFlowInput) => {
    try {
      const result = await startResetPasswordMutation({
        variables: { input },
      });
      return result?.data?.startPasswordResetFlow.success || false;
    } catch {
      // Nothing to do here just make sure any ApolloError is handled.
      // Showing the error to user is already handled by the error link.
    }
  };

  const signOut = async (router?: NextRouter) => {
    // tell server to terminate the session, tokens still needed at this point
    try {
      await signOutMutation();
    } catch {
      // Nothing to do here just make sure any ApolloError is handled.
      // Showing the error to user is already handled by the error link.
    }

    try {
      await cleanupSession();
      if (router) {
        await router.push(Routes.root());
      }
    } catch (e) {
      if (e instanceof Error) {
        // just log the error, no need to show it to the user
        console.error(
          `Encountered error while cleaning up session data - ${e.message}`,
        );
      }
    }
  };

  const cleanupSession = async () => {
    await apolloClient.clearStore();
    deleteTokens();
    deleteCurrentUserDetails();
    deleteSystemUserSelectedEnvironmentId();
    storeDispatch(clearSessionUser());
    Sentry.setUser(null);
    storeDispatch(ResetStoreAction);
  };

  return {
    cleanupSession,
    signIn: signInAndRetry,
    signInWithEmbedTokens,
    signOut,
    startResetPassword,
    resetPassword,
    acceptInvite,
  };
};

export interface UseSessionInfo extends SessionUserState {
  isWorkspaceViewer: boolean;
  isSignedIn: boolean;
  getBrandLogoUrl: () => string;
}

export const useSessionInfo = (): UseSessionInfo => {
  const {
    id,
    isSignedIn,
    firstName,
    lastName,
    email,
    userType,
    systemSelectedEnvironmentId,
    environmentId,
    environmentName,
    environmentRole,
    workspaceId,
    workspaceName,
    workspaceRole,
    customBranding,
  } = useAppSelector((store) => store.session.value);

  const isWorkspaceViewer =
    userType === 'WorkspaceMember' &&
    workspaceRole === WorkspaceMemberRole.WORKSPACE_VIEWER;

  const getBrandLogoUrl = () => customBranding?.logoUrl || INVENTIVE_LOGO;

  return {
    // state
    id,
    isSignedIn,
    isWorkspaceViewer,

    firstName,
    lastName,
    email,

    userType,
    systemSelectedEnvironmentId,
    environmentId,
    environmentName,
    environmentRole,
    workspaceId,
    workspaceName,
    workspaceRole,

    customBranding,
    getBrandLogoUrl,
  };
};
