import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Stack } from '@mui/material';
// components
import Icon from '../../shared/Icon';
import IconButton from '../../shared/IconButton';
import Button from '../../shared/Button';
import { IconName } from '../../shared/Icon/types';
import { GLOBAL_CONTENT_WIDTH } from '../constants';
import { Routes } from '@madeinventive/core-types';

type NavButtonConfig = {
  id: string;
  icon: IconName;
  route: string;
};

const WorkspaceNavBar = ({ workspaceId }: { workspaceId: string }) => {
  const router = useRouter();
  const WorkspaceNavConfigs: NavButtonConfig[] = [
    {
      id: 'home',
      icon: 'stack',
      route: `/workspace/${workspaceId}`,
    },
    {
      id: 'chatHistory',
      icon: 'chat-double',
      route: `/workspace/${workspaceId}/chatHistory`,
    },
    {
      id: 'savedComponents',
      icon: 'bookmark-multi',
      route: `/workspace/${workspaceId}/component`,
    },
    {
      id: 'alerts',
      icon: 'bell-bolt',
      route: `/workspace/${workspaceId}/features`,
    },
  ];

  const showChatButton = useMemo(() => {
    return (
      !router.pathname.endsWith('/chat') &&
      !router.pathname.endsWith('/[componentId]')
    );
  }, [router.pathname]);

  return (
    <Stack
      px={2}
      py={1.5}
      direction='row'
      justifyContent='center'
      width='100%'
      sx={{
        position: 'relative',
        borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
      }}
    >
      <Stack
        id='workspace-navigation'
        overflow='hidden'
        direction='row'
        spacing={1.5}
        maxWidth={GLOBAL_CONTENT_WIDTH}
        width='100%'
      >
        {WorkspaceNavConfigs.map((config) => (
          <WorkspaceNavButton key={config.id} {...config} />
        ))}
      </Stack>
      {showChatButton && (
        <IconButton
          size='large'
          iconName='chat'
          variant='contained'
          onClick={() => {
            router.push(Routes.chat(workspaceId));
          }}
          sx={{
            position: 'absolute',
            top: -16,
            left: 16,
            transform: 'translate(0%, -100%)',
            border: (theme) => `1px solid ${theme.palette.border.active}`,
          }}
        />
      )}
    </Stack>
  );
};

export default WorkspaceNavBar;

const WorkspaceNavButton = ({ id, icon, route }: NavButtonConfig) => {
  const router = useRouter();
  const isSelected = useMemo(() => {
    if (id === 'home') {
      return router.asPath === route;
    } else {
      return router.asPath.includes(route);
    }
  }, [id, router, route]);

  const handleClick = useCallback(() => {
    router.push(route);
  }, [route, router]);

  return (
    <Button
      variant='text'
      fullWidth
      disableRipple
      size='small'
      aria-label={`${id}-${isSelected}`}
      className={isSelected ? 'active' : ''}
      sx={{
        borderRadius: 60,
        '&.active': {
          backgroundColor: 'primary.wash',
          border: (theme) => `1px solid ${theme.palette.border.active}`,
        },
      }}
      onClick={handleClick}
    >
      <Icon name={icon} size='small' />
    </Button>
  );
};
