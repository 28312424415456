import { useCallback } from 'react';

// types
import {
  useScheduleVisualizationMutation, // deprecated
  useSaveChatResponseVisualizationMutation,
  WorkspaceComponentsDocument,
  SaveChatResponseVisualizationInput,
  Feature,
} from '../generated/types';
import { ScheduleVizFormValues } from '../components/Visualization/types';

// hooks
import {
  useAppDispatch,
  useToast,
  useSessionInfo,
  useAppSelector,
  useDialog,
  useDrawer,
} from '../hooks';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import { DRAWER_IDS } from '../components/registeredDrawers/drawerRegistry';
import { useVisualizationContext } from '../components/VisualizationV2/VisualizationProvider';

// slices
import { addFeature } from '../store/slices/workspace';
import { addNewComponentId } from '../store/slices/workspaceComponents';
import { getExploreInfoByWsExploreId } from '../store/slices/loadedWorkspaceExplores';

// etc
import {
  initialFormValues,
  validationSchema,
} from '../components/Visualization/ScheduleVisualizationForm';

export const useVisualization = (workspaceId: string) => {
  // entity id is either chatResponseId or componentId
  const { entityId, wsExploreId, vizTitle } = useVisualizationContext();
  const { showInfoToast } = useToast();
  const { email: userEmail } = useSessionInfo();

  const loadedExplores = useAppSelector((store) => store.loadedExplores.value);

  const exploreInfo = getExploreInfoByWsExploreId(
    loadedExplores,
    workspaceId,
    wsExploreId ?? '',
  );

  const envExploreId = exploreInfo?.envExploreId ?? '';

  const storeDispatch = useAppDispatch();

  const [saveChatResponseVisualization] =
    useSaveChatResponseVisualizationMutation({
      refetchQueries: [
        {
          query: WorkspaceComponentsDocument,
          variables: {
            id: workspaceId,
          },
        },
      ],
    });

  const [scheduleVisualization] = useScheduleVisualizationMutation({
    refetchQueries: [
      {
        query: WorkspaceComponentsDocument,
        variables: {
          id: workspaceId,
        },
      },
    ],
  });

  const saveViz = useCallback(
    async ({ chatResponseId, name }: SaveChatResponseVisualizationInput) => {
      const result = await saveChatResponseVisualization({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      });

      if (result.data?.saveChatResponseVisualization.component) {
        storeDispatch(
          addNewComponentId(
            result.data.saveChatResponseVisualization.component.id,
          ),
        );
      }
    },
    [saveChatResponseVisualization, storeDispatch],
  );

  const scheduleViz = useCallback(
    async ({
      entityId,
      scheduleVizFormValues,
    }: {
      entityId: string;
      scheduleVizFormValues: ScheduleVizFormValues;
    }) => {
      const result = await scheduleVisualization({
        variables: {
          input: {
            workspaceId,
            entityId,
            scheduleVizConfig: {
              cronSchedule: scheduleVizFormValues.cron,
              to: scheduleVizFormValues.recipients.join(', '),
            },
          },
        },
      });

      const feature = result.data?.scheduleVisualization.feature;
      const component = result.data?.scheduleVisualization.component;

      if (feature && feature.__typename === 'Feature') {
        storeDispatch(addFeature(feature as Feature));
      }

      // if the component is a new component, add it to the store
      if (
        component &&
        component.__typename === 'Component' &&
        entityId !== component.id
      ) {
        storeDispatch(addNewComponentId(component.id));
      }
    },
    [scheduleVisualization, storeDispatch, workspaceId],
  );

  const handleScheduleSubmit = useCallback(
    async (scheduleVizFormValues: ScheduleVizFormValues) => {
      await scheduleViz({
        entityId,
        scheduleVizFormValues,
      });
      showInfoToast('Scheduled.');
    },
    [entityId, scheduleViz, showInfoToast],
  );

  const { showDrawer: showScheduleVizDrawer } = useDrawer({
    id: DRAWER_IDS.SCHEDULE_VISUALIZATION,
    title: 'Schedule',
    isFormikDrawer: true,
    formikProps: {
      initialFormValues: {
        ...initialFormValues,
        recipients: [userEmail],
      },
      submitForm: handleScheduleSubmit,
      validationSchema,
      submitButtonText: 'Schedule',
      validateOnChange: true,
      validateOnBlur: true,
    },
  });

  const { showDrawer: showCreateAlertDrawer } = useDrawer({
    id: DRAWER_IDS.CREATE_ALERT,
    title: 'Create alert',
    contentProps: {
      isDrawer: true,
      workspaceId,
      envExploreId,
      vizTitle,
      entityId,
    },
  });

  const { showDialog: showSaveToCreateDialog } = useDialog({
    id: DIALOG_IDS.SAVE_TO_CREATE,
    title: 'Save to Create (admins only)',
    contentProps: {
      chatResponseId: entityId,
    },
  });

  const saveVizWithConfirmation = useCallback(async () => {
    await saveViz({ chatResponseId: entityId });
    showInfoToast('Saved.');
  }, [saveViz, entityId, showInfoToast]);

  return {
    saveViz,
    scheduleViz,
    showScheduleVizDrawer,
    showCreateAlertDrawer,
    saveVizWithConfirmation,
    showSaveToCreateDialog,
  };
};
